<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/leveeDeFonds'">
          Dettes >
        </router-link>
        <span>Détails de dette</span>
      </div>
    </div>

    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails de la dette
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row action-row justify-content-center py-1">
      <div class="col-md-2 text-center">
        <button type="button"
                class="btn btn-f-blue"
                @click="goBack()">
          &#x21E6; Retour
        </button>
      </div>
      <div class="col-md-3 text-center"
           v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')">
        <button type="button"
                class="btn btn-f-blue"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal">
          Importer tableau d'Amortissement
        </button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-f-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Détail_dette_bancaire_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Détails dettes bancaires au {{ dateJour }} 
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <div class="row mt-3">
            <table class="table col-md-8 mx-auto"
                   style="font-size: 0.8em;">
              <thead>
                <tr class="bg-info text-white">
                  <th style="width:35%">Source de la dette</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Base</td>
                  <td>{{ form.base }}</td>
                </tr>
                <tr>
                  <td scope="row">Nom du prêteur</td>
                  <td>{{ leveefnd.levee_fond.preteur.denominantion }} ({{ leveefnd.levee_fond.preteur.sigle }})</td>
                </tr>
              </tbody>
              <thead>
                <tr class="bg-info text-white">
                  <th>Informations de la dette</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Nature de la dette</td>
                  <td>{{ form.nature_dette }}</td>
                </tr>
                <tr>
                  <td scope="row">Objet de la dette</td>
                  <td>{{ form.objet_dette }}</td>
                </tr>
                <tr>
                  <td scope="row">Montant du prêt</td>
                  <td>{{ form.montant_pret.toLocaleString() }}</td>
                </tr>
                <tr>
                  <td scope="row">Montant des tirages</td>
                  <td>{{ form.montant_tirages }}</td>
                </tr>
                <tr>
                  <td scope="row">Taux d'intérêt</td>
                  <td>{{ form.taux_interet }}</td>
                </tr>
                <tr>
                  <td scope="row">Date de mise en place</td>
                  <td>{{ form.date_mise_place }}</td>
                </tr>
                <tr>
                  <td scope="row">Date de fin de remboursement</td>
                  <td>{{ form.date_fin_remboursement }}</td>
                </tr>
                <tr>
                  <td scope="row">Garanties accordées</td>
                  <td>{{ form.garantie_accordee }}</td>
                </tr>
                <tr>
                  <td scope="row">Nature de la garantie</td>
                  <td>{{ form.nature_garantie }}</td>
                </tr>
                <tr>
                  <td scope="row">Garantie nécesaire</td>
                  <td>{{ Math.round(Number(form.garantie_necessaire)).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td scope="row">Périodicité</td>
                  <td>{{ form.periodicite }}</td>
                </tr>
                <tr>
                  <td scope="row">Durée de remboursement</td>
                  <td>{{ form.duree_remboursement }}</td>
                </tr>
              </tbody>
              <thead>
                <tr class="bg-info text-white">
                  <th>Différés de la dette</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Différé en principal</td>
                  <td>{{ form.differe_en_principal }}</td>
                </tr>
                <tr>
                  <td scope="row">Période différée en principal </td>
                  <td>{{ form.periode_differe_en_principal }}</td>
                </tr>
                <tr>
                  <td scope="row">Différé en intérêt</td>
                  <td>{{ form.differe_en_interet }}</td>
                </tr>
                <tr>
                  <td scope="row">Période différée en intérêt </td>
                  <td>{{ form.periode_differe_en_interet }}</td>
                </tr>
                <tr>
                  <td scope="row">Différé en assurance</td>
                  <td>{{ form.differe_en_assurence }}</td>
                </tr>
                <tr>
                  <td scope="row">Période différée en assurance </td>
                  <td>{{ form.periode_differe_en_assurence }}</td>
                </tr>
                <tr>
                  <td scope="row">Différé en TOB</td>
                  <td>{{ form.differe_en_tob }}</td>
                </tr>
                <tr>
                  <td scope="row">Période différée en TOB </td>
                  <td>{{ form.periode_differe_en_tob }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table table-striped mt-3"
                 style="width:95%;font-size: 0.9em;">
            <thead>
              <tr>
                <th class="tht-blue text-center"
                    colspan="11">TABLEAU D'AMORTISSEMENTS</th>
              </tr>
              <tr>
                <th class="th-blue">N° Echelon</th>
                <th class="th-blue">Date</th>
                <th class="th-blue text-right">Capital restant du</th>
                <th class="th-blue text-right">Intérêts à payer</th>
                <th class="th-blue text-right">Capital à rembourser</th>
                <th class="th-blue text-right">Frais</th>
                <th class="th-blue text-right">Echéance à payer</th>
                <th class="th-blue text-right">Echéance payée</th>
                <th class="th-blue text-right">Ecart</th>
                <th class="th-blue">Date de paiement échéance</th>
                <th class="th-blue">Observation</th>
              </tr>
            </thead>
            <tbody v-if="!loaded">
              <tr>
                <td class="text-center"
                    colspan="11">Chargement des données...</td>
              </tr>
            </tbody>
            <tbody v-else-if="loaded && long==0">
              <tr>
                <td class="text-center"
                    colspan="11">Aucune donnée enrigistrée.</td>
              </tr>
            </tbody>
            <tbody v-else-if="loaded && long>0">
              <tr v-for="(amortissement,amorkey) in amortissements.donnees"
                  :key="amorkey">
                <td>{{ amortissement.nro_echelon }}</td>
                <td>{{ amortissement.date }}</td>
                <td class="text-right">{{ Math.round(Number(amortissement.capital_restant_du)).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(Number(amortissement.interets_a_payer)).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(Number(amortissement.capital_rembourse)).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(Number(amortissement.frais)).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(Number(amortissement.echeance_payee)).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(Number(amortissement.montant_paiement_dette)).toLocaleString() }}      </td>
                <td class="text-right">
                  {{ Math.round(Number(amortissement.echeance_payee-amortissement.montant_paiement_dette)).     toLocaleString() }}
                </td>
                <td class="">{{amortissement.date_paiement_dette}}</td>
                <td class="">{{amortissement.observation}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr style="font-weight: bold;">
                <td class="tht-blue text-center"
                    colspan="2">TOTAUX</td>
                <td class="tht-blue text-right">{{totaux.capital_restant_du.toLocaleString()}}</td>
                <td class="tht-blue text-right">{{totaux.interets_a_payer.toLocaleString()}}</td>
                <td class="tht-blue text-right">{{totaux.capital_rembourse.toLocaleString()}}</td>
                <td class="tht-blue text-right">{{totaux.frais.toLocaleString()}}</td>
                <td class="tht-blue text-right">{{totaux.echeance_a_payer.toLocaleString()}}</td>
                <td class="tht-blue text-right">{{totaux.echeance_payee.toLocaleString()}}</td>
                <td class="tht-blue text-right">{{totaux.ecart.toLocaleString()}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

    <div class="row my-4">
      <div class="col-md-11 mx-auto">
        <form class="accordion" 
              id="accordionExample"
              v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')">
          <table  class="table col-md-8 mx-auto">
            <thead class="card-header" 
                   id="headingOne">
              <tr class="tht-blue"
                  data-toggle="collapse" 
                  data-target="#collapseOne" 
                  aria-expanded="true" 
                  aria-controls="collapseOne">
                <th class="pl-3"> Source de la dette</th>
              </tr>
            </thead>
            <div id="collapseOne" 
                 class="collapse show" 
                 aria-labelledby="headingOne" 
                 data-parent="#accordionExample">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-5"
                       id="base"
                       ref="base">
                    <label>Base</label>
                  </div>
                  <div class="form-group col-md-6"
                       id="base"
                       ref="base">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-on:click="removeSPan('base')"
                            v-model="form.base">
                      <option value="SIEGE">SIEGE</option>
                      <option value="PEAGE">PEAGE</option>
                      <option value="PESAGE">PESAGE</option>
                      <option value="AIRE_STATIONNEMENT">AIRE DE STATIONNEMENT</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label class="req">Nom du preteur</label>
                  </div>  
                  <div class="form-group col-md-6">
                    <v-select :options="listDesBanques"
                              :reduce="preteur => preteur.id"
                              label="sigle"
                              ref="preteur"
                              v-model="form.preteur"
                              @input="removeSPan('preteur')"
                              placeholder="Choisir une preteur">
                      <span slot="no-options"
                            @click="$refs.preteur.open = false"
                            class="text-danger">
                        Aucune Banque trouvée
                      </span>
                    
                    </v-select>
                    <span v-if="form.errors().has('preteur') && affiche.preteur !== 'preteur'"
                          v-text="form.errors().get('preteur')"
                          class="errorMsg"> </span>
                  </div>
                </div>
              </div>
            </div>
            <thead class="card-header" 
                   id="headingTwo">
              <tr class="tht-blue"
                  data-toggle="collapse" 
                  data-target="#collapseTwo" 
                  aria-expanded="true" 
                  aria-controls="collapseTwo">
                <th class="pl-3"> Informations de la dette</th>
              </tr>
            </thead>
            <div id="collapseTwo" 
                 class="collapse show" 
                 aria-labelledby="headingTwo" 
                 data-parent="#accordionExample">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-5"
                       id="nature_dette"
                       ref="nature_dette">
                    <label>Nature dette</label>
                  </div>
                  <div class="form-group col-md-6"
                       id="nature_dette"
                       ref="nature_dette">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-on:click="removeSPan('nature_dette')"
                            v-model="form.nature_dette">
                      <option value="Levée de fonds">Levée de fonds</option>
                      <option value="Levée de fonds (Court terme)">Levée de fonds (Court terme)</option>
                      <option value="Levée de fonds (Moyen terme)">Levée de fonds (Moyen terme)</option>
                      <option value="Crédit relais">Crédit relais</option>
                      <option value="Crédit (Court terme)">Crédit (Court terme)</option>
                      <option value="Crédit (Moyen terme)">Crédit (Moyen terme)</option>
                      <option value="Facilité (Court terme)">Facilité (Court terme)</option>
                      <option value="Facilité (Moyen terme)">Facilité (Moyen terme)</option>
                      <option value="Traites avalisées">Traites avalisées</option>
                    </select>
                    <span v-if="form.errors().has('nature_dette') && affiche.nature_dette !== 'nature_dette'"
                          v-text="form.errors().get('nature_dette')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Objet de la dette</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="text"
                           class="form-control"
                           v-model="form.objet_dette"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('objet_dette')"
                           placeholder="objet de la dette" 
                           autocomplete="off"/>
                    <span v-if="form.errors().has('objet_dette') && affiche.objet_dette !== 'objet_dette'"
                          v-text="form.errors().get('objet_dette')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                
                  <div class="form-group col-md-5">
                    <label>Montant du prêt</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="text"
                           class="form-control"
                           v-model="montant_pret"
                           @input="addSpace('montant_pret')"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('montant_pret')"
                           placeholder=" 1000" 
                           autocomplete="off"/>
                    <span v-if="form.errors().has('montant_pret') && affiche.montant_pret !== 'montant_pret'"
                          v-text="form.errors().get('montant_pret')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Montant des tirages</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="text"
                           class="form-control"
                           v-model="montant_tirages"
                           aria-describedby="helpId"
                           @input="addSpace('montant_tirages')"
                           v-on:click="removeSPan('montant_tirages')"
                           placeholder="1000" 
                           autocomplete="off"/>
                    <span v-if="form.errors().has('montant_tirages') && affiche.montant_tirages !== 'montant_tirages'"
                          v-text="form.errors().get('montant_tirages')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label class="req">Taux d'intérêt</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="number"
                           class="form-control"
                           name=""
                           v-model="form.taux_interet"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('taux_interet')"
                           placeholder="2.3" 
                           max="100"/>
                    <span v-if="form.errors().has('taux_interet') && affiche.taux_interet !== 'taux_interet'"
                          v-text="form.errors().get('taux_interet')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label class="req">Date mise en place</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="date"
                           class="form-control"
                           name=""
                           v-model="form.date_mise_place"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('date_mise_place')"
                           placeholder="" />
                    <span v-if="form.errors().has('date_mise_place') && affiche.date_mise_place !== 'date_mise_place'"
                          v-text="form.errors().get('date_mise_place')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                
                  <div class="form-group col-md-5">
                    <label class="req">Date fin remboursement</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="date"
                           class="form-control"
                           name=""
                           v-model="form.date_fin_remboursement"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('date_fin_remboursement')"
                           placeholder="" />
                    <span
                      v-if="form.errors().has('date_fin_remboursement') && affiche.date_fin_remboursement !== 'date_fin_remboursement'"
                      v-text="form.errors().get('date_fin_remboursement')"
                      class="errorMsg"></span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label >Garanties accordées</label>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-model="form.garantie_accordee">
                      <option value="">Choisissez une option</option>
                      <option value="OUI">Oui</option>
                      <option value="NON">Non</option>
                    </select>
                  
                    <span v-if="form.errors().has('garantie_accordee') && affiche.garantie_accordee !== 'garantie_accordee'"
                          v-text="form.errors().get('garantie_accordee')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label >Nature de la garantie</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="text"
                           class="form-control"
                           name=""
                           v-model="form.nature_garantie"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('nature_garantie')" 
                           autocomplete="off"/>
                    <span v-if="form.errors().has('nature_garantie') && affiche.nature_garantie !== 'nature_garantie'"
                          v-text="form.errors().get('nature_garantie')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label >Garantie nécessaire</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="text"
                           class="form-control"
                           name=""
                           v-model="garantie_necessaire"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('garantie_necessaire')"
                           @input="addSpace('garantie_necessaire')" 
                           autocomplete="off"/>
                    <span v-if="form.errors().has('garantie_necessaire') && affiche.garantie_necessaire !== 'garantie_necessaire'"
                          v-text="form.errors().get('garantie_necessaire')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label >Garantie échue</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="text"
                           class="form-control"
                           name=""
                           v-model="echeance_echue"
                           @input="addSpace('echeance_echue')"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('echeance_echue')" 
                           autocomplete="off"/>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label >Date de restitution de la Garantie</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="date"
                           class="form-control"
                           name=""
                           v-model="form.date_echeance_echue"/>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label class="req">Périodicité</label>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control"
                            name=""
                            v-on:click="removeSPan('periodicite')"
                            v-model="form.periodicite">
                      <option value="">Choisissez une option</option>
                      <option value="HEBDOMADAIRE">Hebdomadaire</option>
                      <option value="MENSUEL">Mensuelle</option>
                      <option value="TRIMESTRIEL">Trimestrielle</option>
                      <option value="QUADRIMESTRE">Quadrimestrielle</option>
                      <option value="SEMESTRIEL">Semestrielle</option>
                      <option value="ANNUEL">Annuelle</option>
                    </select>
                  
                    <span v-if="form.errors().has('periodicite') && affiche.periodicite !== 'periodicite'"
                          v-text="form.errors().get('periodicite')"
                          class="errorMsg"> </span>
                  </div>
                
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label class="req">Durée de remboursement</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="number"
                           class="form-control"
                           name=""
                           v-model="form.duree_remboursement"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('duree_remboursement')"
                           placeholder="5" />
                    <span v-if="form.errors().has('duree_remboursement') && affiche.duree_remboursement !== 'duree_remboursement'"
                          v-text="form.errors().get('duree_remboursement')"
                          class="errorMsg"> </span>
                  </div>
                </div>
              </div>
            </div>
            <thead class="card-header" 
                   id="headingThree">
              <tr class="tht-blue"
                  data-toggle="collapse" 
                  data-target="#collapseThree" 
                  aria-expanded="true" 
                  aria-controls="collapseThree">
                <th class="pl-3"> Différés de la dette</th>
              </tr>
            </thead>
            <div id="collapseThree" 
                 class="collapse show" 
                 aria-labelledby="headingThree" 
                 data-parent="#accordionExample">
              <div class="card-body">
                <div class="form-row row">
                  <div class="form-group col-md-5">
                    <label>Différé en principal</label>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-model="form.differe_en_principal"
                            @change="differe('periode_differe_en_principal','differe_en_principal')">
                      <option value="">Choisissez une option</option>
                      <option value="OUI">Oui</option>
                      <option value="NON">Non</option>
                    </select>
                    <span v-if="form.errors().has('differe_en_principal') && affiche.differe_en_principal !== 'differe_en_principal'"
                          v-text="form.errors().get('differe_en_principal')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Période différée en principal</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="number"
                           class="form-control"
                           name=""
                           @input="convertNumber('periode_differe_en_principal')"
                           v-model="form.periode_differe_en_principal"
                           ref="periode_differe_en_principal"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('periode_differe_en_principal')"
                           placeholder="5" />
                    <span v-if="form.errors().has('periode_differe_en_principal') && affiche.periode_differe_en_principal !== 'periode_differe_en_principal'"
                          v-text="form.errors().get('periode_differe_en_principal')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Différé en intérêt</label>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-model="form.differe_en_interet">
                      <option value="">Choisissez une option</option>
                      <option value="OUI">Oui</option>
                      <option value="NON">Non</option>
                    </select>
                    <span v-if="form.errors().has('differe_en_interet') && affiche.differe_en_interet !== 'differe_en_interet'"
                          v-text="form.errors().get('differe_en_interet')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Période différée en intérêt</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="number"
                           class="form-control"
                           name=""
                           @input="convertNumber('periode_differe_en_interet')"
                           v-model="form.periode_differe_en_interet"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('periode_differe_en_interet')"
                           placeholder="5" />
                    <span v-if="form.errors().has('periode_differe_en_interet') && affiche.periode_differe_en_interet !== 'periode_differe_en_interet'"
                          v-text="form.errors().get('periode_differe_en_interet')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Différé en assurance</label>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-model="form.differe_en_assurence">
                      <option value="">Choisissez une option</option>
                      <option value="OUI">Oui</option>
                      <option value="NON">Non</option>
                    </select>
                    <span v-if="form.errors().has('differe_en_assurence') && affiche.differe_en_assurence !== 'differe_en_assurence'"
                          v-text="form.errors().get('differe_en_assurence')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Période différée en assurance</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="number"
                           class="form-control"
                           name=""
                           @input="convertNumber('periode_differe_en_assurance')"
                           v-model="form.periode_differe_en_assurance"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('periode_differe_en_assurance')"
                           placeholder="5" />
                    <span v-if="form.errors().has('periode_differe_en_assurance') && affiche.periode_differe_en_assurance !== 'periode_differe_en_assurance'"
                          v-text="form.errors().get('periode_differe_en_assurance')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Différé en TOB</label>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control" 
                            name="" 
                            id=""
                            v-model="form.differe_en_tob">
                      <option value="">Choisissez une option</option>
                      <option value="OUI">Oui</option>
                      <option value="NON">Non</option>
                    </select>
                    <span v-if="form.errors().has('differe_en_tob') && affiche.differe_en_tob !== 'differe_en_tob'"
                          v-text="form.errors().get('differe_en_tob')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-5">
                    <label>Période différée en TOB</label>
                  </div>
                  <div class="form-group col-md-6">
                    <input type="number"
                           class="form-control"
                           name=""
                           @input="convertNumber('periode_differe_en_tob')"
                           v-model="form.periode_differe_en_tob"
                           aria-describedby="helpId"
                           v-on:click="removeSPan('periode_differe_en_tob')"
                           placeholder="5" />
                    <span v-if="form.errors().has('periode_differe_en_tob') && affiche.periode_differe_en_tob !== 'periode_differe_en_tob'"
                          v-text="form.errors().get('periode_differe_en_tob')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <button class="btn btn-f-blue mr-4"
                          type="button"
                          @click="submit"><i class="flaticon flaticon-floppy-disk"></i>&nbsp;&nbsp; Enregistrer</button>
                
                  <button type="button"
                          class="btn btn-f-blue cancle_btn"
                          data-toggle="modal"
                          data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i>&nbsp;&nbsp; Annuler</button>
                </div>
              </div>
            </div>
          </table>
        </form>
        <!---------------------------------------------- DETAILS POUR DT ------------------------------------->
        <div class="row mt-3"
             v-if="(user[1] == 'FER' && (user[0] != 'DAF'&& user[0]!='DCG'))&&(user[1] == 'FER' && user[0] != 'ADMIN')">
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.8em;">
            <thead>
              <tr class="bg-info text-white">
                <th style="width:20%">Source de la dette</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">Base</td>
                <td>{{ form.base }}</td>
              </tr>
              <tr>
                <td scope="row">Nom du prêteur</td>
                <td>{{ leveefnd.levee_fond.preteur.denominantion }} ({{ leveefnd.levee_fond.preteur.sigle }})</td>
              </tr>
            </tbody>
            <thead>
              <tr class="bg-info text-white">
                <th>Informations de la dette</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">Nature de la dette</td>
                <td>{{ form.nature_dette }}</td>
              </tr>
              <tr>
                <td scope="row">Objet de la dette</td>
                <td>{{ form.objet_dette }}</td>
              </tr>
              <tr>
                <td scope="row">Montant du prêt</td>
                <td>{{ form.montant_pret.toLocaleString() }}</td>
              </tr>
              <tr>
                <td scope="row">Montant des tirages</td>
                <td>{{ form.montant_tirages }}</td>
              </tr>
              <tr>
                <td scope="row">Taux d'intérêt</td>
                <td>{{ form.taux_interet }}</td>
              </tr>
              <tr>
                <td scope="row">Date de mise en place</td>
                <td>{{ form.date_mise_place }}</td>
              </tr>
              <tr>
                <td scope="row">Date de fin de remboursement</td>
                <td>{{ form.date_fin_remboursement }}</td>
              </tr>
              <tr>
                <td scope="row">Garanties accordées</td>
                <td>{{ form.garantie_accordee }}</td>
              </tr>
              <tr>
                <td scope="row">Nature de la garantie</td>
                <td>{{ form.nature_garantie }}</td>
              </tr>
              <tr>
                <td scope="row">Garantie nécesaire</td>
                <td>{{ Math.round(Number(form.garantie_necessaire)).toLocaleString() }}</td>
              </tr>
              <tr>
                <td scope="row">Périodicité</td>
                <td>{{ form.periodicite }}</td>
              </tr>
              <tr>
                <td scope="row">Durée de remboursement</td>
                <td>{{ form.duree_remboursement }}</td>
              </tr>
            </tbody>
            <thead>
              <tr class="bg-info text-white">
                <th>Différés de la dette</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">Différé en principal</td>
                <td>{{ form.differe_en_principal }}</td>
              </tr>
              <tr>
                <td scope="row">Période différée en principal </td>
                <td>{{ form.periode_differe_en_principal }}</td>
              </tr>
              <tr>
                <td scope="row">Différé en intérêt</td>
                <td>{{ form.differe_en_interet }}</td>
              </tr>
              <tr>
                <td scope="row">Période différée en intérêt </td>
                <td>{{ form.periode_differe_en_interet }}</td>
              </tr>
              <tr>
                <td scope="row">Différé en assurance</td>
                <td>{{ form.differe_en_assurence }}</td>
              </tr>
              <tr>
                <td scope="row">Période différée en assurance </td>
                <td>{{ form.periode_differe_en_assurence }}</td>
              </tr>
              <tr>
                <td scope="row">Différé en TOB</td>
                <td>{{ form.differe_en_tob }}</td>
              </tr>
              <tr>
                <td scope="row">Période différée en TOB </td>
                <td>{{ form.periode_differe_en_tob }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    <!-- TABLEAU D'AMORTISSEMENT -->
    <div class="row"
         v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tht-blue text-center"
                colspan="12">TABLEAU D'AMORTISSEMENTS</th>
          </tr>
          <tr>
            <th class="th-blue">N° Echelon</th>
            <th class="th-blue">Date</th>
            <th class="th-blue text-right">Capital restant du</th>
            <th class="th-blue text-right">Intérêts à payer</th>
            <th class="th-blue text-right">Capital à rembourser</th>
            <th class="th-blue text-right">Frais</th>
            <th class="th-blue text-right">Echéance à payer</th>
            <th class="th-blue text-right">Echéance payée</th>
            <th class="th-blue text-right">Ecart</th>
            <th class="th-blue">Date de paiement échéance</th>
            <th class="th-blue">Observation</th>
            <th class="th-blue">Action</th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr>
            <td class="text-center"
                colspan="12">Chargement des données...</td>
          </tr>
        </tbody>
        <tbody v-else-if="loaded && long==0">
          <tr>
            <td class="text-center"
                colspan="12">Aucune donnée enrigistrée.</td>
          </tr>
        </tbody>
        <tbody v-else-if="loaded && long>0">
          <tr v-for="(amortissement,amorkey) in amortissements.donnees"
              :key="amorkey">
            <td>{{ amortissement.nro_echelon }}</td>
            <td>{{ amortissement.date }}</td>
            <td class="text-right">{{ Math.round(Number(amortissement.capital_restant_du)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(amortissement.interets_a_payer)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(amortissement.capital_rembourse)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(amortissement.frais)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(amortissement.echeance_payee)).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(Number(amortissement.montant_paiement_dette)).toLocaleString() }}</td>
            <td class="text-right">
              {{ Math.round(Number(amortissement.echeance_payee-amortissement.montant_paiement_dette)).toLocaleString() }}
            </td>
            <td class="">{{amortissement.date_paiement_dette}}</td>
            <td class="">{{amortissement.observation}}</td>
            <td class="text-right">
              <button class="btn btn-blue"
                      data-toggle="modal"
                      ref="modal_button1"
                      data-target="#exampleModal1"
                      @click="chargeEcheance(amortissement)">Payer</button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="font-weight: bold;">
            <td class="tht-blue text-center"
                colspan="2">TOTAUX</td>
            <td class="tht-blue text-right">{{totaux.capital_restant_du.toLocaleString()}}</td>
            <td class="tht-blue text-right">{{totaux.interets_a_payer.toLocaleString()}}</td>
            <td class="tht-blue text-right">{{totaux.capital_rembourse.toLocaleString()}}</td>
            <td class="tht-blue text-right">{{totaux.frais.toLocaleString()}}</td>
            <td class="tht-blue text-right">{{totaux.echeance_a_payer.toLocaleString()}}</td>
            <td class="tht-blue text-right">{{totaux.echeance_payee.toLocaleString()}}</td>
            <td class="tht-blue text-right">{{totaux.ecart.toLocaleString()}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- Modal importation tableau d'amortissement-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer tableau d'amortissements
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="viderFile()"
                    ref="btn-close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for=""
                >Tableau d'amortissements</label
                >
                <div class="custom-file">
                  <label class="custom-file-label">
                    {{ inputFiles.donnees_histo }}
                  </label>

                  <input type="file"
                         name="etat_comptable"
                         class="custom-file-input"
                         id="donnees_histo"
                         ref="donnees_histo"
                         v-on:change="handleFileUpload('donnees_histo')"
                         accept=".xlsx,.csv" />
                  <span v-if="form.errors().has('donnees_histo')"
                        v-text="form.errors().get('donnees_histo')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('Amortissement.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal"
                    @click="viderFile()">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="uploadAmortissement()">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Paiement d'échéances -->
    <div class="modal fade"
         id="exampleModal1"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModal1Label"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Payer une écheance
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close1">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Montant du paiement</label>
                <input type="text"
                       class="form-control"
                       v-model="montant_paiement_dette"
                       @input="addSpaceAmort('montant_paiement_dette')">
              </div>
              <div class="form-group">
                <label for="">Date de paiement</label>
                <input type="date"
                       class="form-control"
                       v-model="paiements.date_paiement_dette">
              </div>
              <div class="form-group">
                <label for="">observation</label>
                <textarea class="form-control"
                          id=""  
                          rows="4"
                          v-model="paiements.observation"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="loadPaiement()">Enregistrer</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal"
                    @click="viderForm()">Annuler</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
div ::placeholder {
  color: rgba(220, 220, 220, 0.897);
}
/* form select.form-control,
form input.form-control,
form label,
table.table th,
table.table td{
  font-size: 0.6em;
} */
.card-body input.form-control {
  border-radius: 5px;
  border: 1px solid rgba(220, 220, 220);
}
/*.v-select, input.vs__search,input.vs__search:focus{
  font-size: 0.7em;
} */

</style>
<script>
import form from "vuejs-form"
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import VSelect from "vue-select"
import 'vue-select/dist/vue-select.css'
import utils from "@/assets/js/utils"
import VueHtml2pdf from 'vue-html2pdf'


export default {
  name: "CreateLeveeFond",
  components: {
    Notif,
    VSelect,
    VueHtml2pdf
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    montant_pret: "",
    montant_tirages: "",
    garantie_necessaire: "",
    echeance_echue: "",
    ttc: "",
    dateJour:"",
    tva: "",
    form: form({
      id:"",
      nature_dette: "",
      objet_dette: "",
      preteur: "",
      montant_pret: "",
      montant_tirages: "",
      date_mise_place: "",
      date_fin_remboursement: "",
      date_echeance_echue: "",
      periodicite: "",
      differe_en_principal:"principal",
      differe_en_interet:"interet",
      differe_en_tob:"tob",
      differe_en_assurence:"assurence",
      duree_remboursement: "",
      taux_interet: "",
      periode_differe_en_principal: 0,
      periode_differe_en_interet: 0,
      periode_differe_en_tob: 0,
      periode_differe_en_assurance: 0,
      garantie_accordee: "",
      nature_garantie: "",
      garantie_necessaire: "",
      echeance_echue: "",
      ttc:"",
      tva:""
    })
      .rules({
        id:"required",
      }),
    montant_paiement_dette:"",
    paiements:{
      montant_paiement_dette:"",
      amortissement:"",
      date_paiement_dette:"",
      observation:"",
    },
    affiche: {
      nature_dette: "nature_dette:true",
      objet_dette: "objet_dette:true",
      preteur: "preteur:true",
      montant_pret: "montant_pret:true",
      montant_tirages: "montant_tirages:true",
      date_mise_place: "date_mise_place:true",
      date_fin_remboursement: "date_fin_remboursement:true",
      periodicite: "periodicite:true",
      differe_en_principal:"differe_en_principal:true",
      differe_en_interet:"differe_en_interet:true",
      differe_en_tob:"differe_en_tob:true",
      differe_en_assurence:"differe_en_assurence:true",
      duree_remboursement: "duree_remboursement:true",
      taux_interet: "taux_interet:true",
      periode_differe_en_principal: "periode_differe_en_principal:true",
      periode_differe_en_interet: "periode_differe_en_interet:true",
      periode_differe_en_tob: "periode_differe_en_tob:true",
      periode_differe_en_assurance: "periode_differe_en_assurance:true",
      garantie_accordee: "garantie_accordees:true",
      nature_garantie: "nature_garantie:true",
      garantie_necessaire: "garantie_necessaire:true",
      echeance_echue: "echeance_echue:true",
      ttc: "ttc:true",
      tva: "tva:true"
    },
    totaux:{
      capital_restant_du:0,
      interets_a_payer:0,
      capital_rembourse:0,
      frais:0,
      echeance_a_payer:0,
      echeance_payee:0,
      ecart:0,
    },
    listDesBanques:[],
    user:"",
    url:"",
    listExtensionFiles:"",
    uploadfile:"",
    inputFiles: {  
      donnees_histo:"" 
    },
    formAmortissement: form({
      donnees_histo:"" ,
      id_dette:""
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    loaded: false,
    long:0,
  }),
  created() {
    this.getLeveeFond(this.$route.params.id)
    this.listBanques()
    this.getAmortissements({id_dette:this.$route.params.id})
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles = utils.formaFichierAccepte
    var jour = new Date()
    this.dateJour = jour.getFullYear()+"-"
    if (jour.getMonth() < 9) {
      this.dateJour +="0"
    }
    this.dateJour+=(jour.getMonth()+1)+"-"
    if (jour.getDate() < 10) {
      this.dateJour +="0" 
    }
    this.dateJour+=jour.getDate()
  },
  watch: {
    banqueSansPage(){
      this.banqueSansPage.donnees.forEach(banqueSansPage =>
        this.listDesBanques.push({id:banqueSansPage.id,sigle:banqueSansPage.sigle})
      )
      // console.log(this.listDesBanques)
    },
    files() {
      if (this.files && this.files["url"]) {
        this.formAmortissement[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.formAmortissement[this.uploadfile]
      }
    },
    successLevee() {
      if (this.successLevee) {
        console.log(this.successLevee)
        this.notif.message = this.successLevee
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "LeveeDeFonds" })
          }.bind(this),
          3000
        )
      }
    },
    amortissements(){
      if (this.amortissements.donnees) {
        this.long=this.amortissements.donnees.length
        this.loaded=true
        this.totaux={capital_restant_du:0, interets_a_payer:0, capital_rembourse:0, frais:0, echeance_a_payer:0, echeance_payee:0, ecart:0}
        this.amortissements.donnees.forEach(amortissement => {
          // this.totaux.capital_restant_du += Number(amortissement.capital_restant_du)
          this.totaux.interets_a_payer += Number(amortissement.interets_a_payer)
          this.totaux.capital_rembourse += Number(amortissement.capital_rembourse)
          this.totaux.frais += Number(amortissement.frais)
          this.totaux.echeance_a_payer += Number(amortissement.echeance_payee)
          this.totaux.echeance_payee += Number(amortissement.montant_paiement_dette)
          this.totaux.ecart += Number(amortissement.echeance_payee-amortissement.montant_paiement_dette)
        })
      }
      console.log(this.totaux)
    },
    paiement(){
      this.notif.message = this.paiement
      this.notif.type = "toast-success"
      this.notif.activated = "activated"
      setTimeout(
        function() {
          this.notif.activated = ""
          this.setPaiement("")
          this.paiements={amortissement:"",date_paiement_dette:"",montant_paiement_dette:""}
          this.getAmortissements({id_dette:this.$route.params.id})
          this.$refs["btn-close1"].click()
        }.bind(this),
        3000
      )
    },
    msgSuccessAmortissement() {
      if (this.msgSuccessAmortissement) {
        console.log(this.msgSuccessAmortissement)
        this.notif.message = this.msgSuccessAmortissement
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessAmortissement("")
            this.long=0
            this.loaded=false
            this.setAmortissements("")
            this.getAmortissements({id_dette:this.$route.params.id})
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        )
      }
    },
    msgFailAmortissement() {
      if (this.msgFailAmortissement) {
        console.log(this.msgFailAmortissement)
        this.notif.message = this.msgFailAmortissement
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailAmortissement("")
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        )
      }
    },
    errorsLevee(){
      if (this.errorsLevee) {
        this.notif.message = this.errorsLevee
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    },
    leveefnd() {
      if (this.leveefnd) {
        this.montant_pret = this.leveefnd.levee_fond.montant_pret.split(".")
        this.montant_tirages = this.leveefnd.levee_fond.montant_tirages.split(".")
        if (this.leveefnd.levee_fond.tva!=null) {
          this.tva = this.leveefnd.levee_fond.tva.split(".")
          this.tva = this.tva[0]
        }else{
          this.tva = 0
        }
        if (this.leveefnd.levee_fond.ttc != null) {
          this.ttc = this.leveefnd.levee_fond.ttc.split(".")
          this.ttc = this.ttc[0]
        } else {
          this.ttc = 0
        }
        this.montant_pret = this.montant_pret[0]
        this.montant_tirages = this.montant_tirages[0]
        this.form.montant_pret=Number(this.montant_pret)
        this.form.montant_tirages=Number(this.montant_tirages)
        this.form.ttc=Number(this.ttc)
        this.form.tva=Number(this.tva)
        if (this.montant_pret !=null && this.montant_pret !="") {
          this.addSpace("montant_pret")  
        }
        if (this.montant_tirages !=null && this.montant_tirages !="") {
          this.addSpace("montant_tirages")  
        }
        if (this.tva !=null && this.tva !="") {
          this.addSpace("tva")  
        }
        if (this.ttc !=null && this.ttc !="") {
          this.addSpace("ttc")  
        }
        
        this.form.id= this.leveefnd.levee_fond.id
        this.form.base=this.leveefnd.levee_fond.base
        this.form.taux_interet= this.leveefnd.levee_fond.taux_interet
        this.form.nature_dette=this.leveefnd.levee_fond.nature_dette
        this.form.objet_dette=this.leveefnd.levee_fond.objet_dette
        this.form.preteur=this.leveefnd.levee_fond.preteur.id
        
        this.form.date_mise_place=this.leveefnd.levee_fond.date_mise_place
        this.form.date_echeance_echue=this.leveefnd.levee_fond.date_echeance_echue
        this.form.date_fin_remboursement=this.leveefnd.levee_fond.date_fin_remboursement
        if (this.leveefnd.levee_fond.periodicite != null) {
          this.form.periodicite=this.leveefnd.levee_fond.periodicite
        }
        this.form.differe_en_principal = this.leveefnd.levee_fond.differe_en_principal
        this.form.differe_en_interet = this.leveefnd.levee_fond.differe_en_interet
        this.form.differe_en_tob = this.leveefnd.levee_fond.differe_en_tob
        this.form.differe_en_assurence = this.leveefnd.levee_fond.differe_en_assurence
        this.form.duree_remboursement=this.leveefnd.levee_fond.duree_remboursement
        if (this.leveefnd.levee_fond.periode_differe_en_principal != null) {
          this.form.periode_differe_en_principal=this.leveefnd.levee_fond.periode_differe_en_principal
        }
        if (this.leveefnd.levee_fond.periode_differe_en_interet!= null) {
          this.form.periode_differe_en_interet=this.leveefnd.levee_fond.periode_differe_en_interet
        }
        if (this.leveefnd.levee_fond.periode_differe_en_tob != null) {
          this.form.periode_differe_en_tob=this.leveefnd.levee_fond.periode_differe_en_tob
        }
        if(this.leveefnd.levee_fond.periode_differe_en_assurance != null){
          this.form.periode_differe_en_assurance=this.leveefnd.levee_fond.periode_differe_en_assurance
        }
        this.form.garantie_accordee=this.leveefnd.levee_fond.garantie_accordee
        this.form.nature_garantie=this.leveefnd.levee_fond.nature_garantie
        this.form.garantie_necessaire=Math.round(Number(this.leveefnd.levee_fond.garantie_necessaire))
        this.garantie_necessaire=Math.round(this.leveefnd.levee_fond.garantie_necessaire)
        this.form.echeance_echue=Math.round(Number(this.leveefnd.levee_fond.echeance_echue))
        this.echeance_echue=Math.round(this.leveefnd.levee_fond.echeance_echue)

      }
    }
  },
  computed: {
    ...mapGetters(["leveefnd", "errorsLevee", "banqueSansPage","successLevee","msgFailAmortissement","msgSuccessAmortissement","amortissements","files","paiement","msgFailPaiement"])
  },
  methods: {
    ...mapActions(["getLeveeFond","listBanques","updateLeveeFonds","getAmortissements","importerAmortissements","saveFile","postPaiement"]),
    ...mapMutations(["setErrors","setSuccess","setMsgFailAmortissement","setMsgSuccessAmortissement","setAmortissements","setFiles","setMsgFailPaiement","setPaiement"]),
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]

      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"public/files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    viderFile(){
      this.inputFiles.donnees_histo=''
      this.setFiles('')
      this.$refs["donnees_histo"].value=""
    },
    viderForm(){
      this.paiements.montant_paiement_dette=''
      this.paiements.date_paiement_dette=''
      this.paiements.amortissement=''
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    convertNumber(libelle){
      this.form[libelle]=Number(this.form[libelle])
    },
    submit() {  
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.updateLeveeFonds(this.form.data)
      } else {
        for (let afficheKey in this.affiche) {
          const tabVale = this.affiche[afficheKey].split(":")
          if (tabVale.length === 1) {
            this.affiche[afficheKey] = tabVale[0] + ":true"
          }
        }
      }
    },
    addSpaceAmort(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.paiements[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.paiements[key] += res[index]
      }
      this.paiements[key]=Number(this.paiements[key])
    },
    uploadAmortissement(){
      console.log("in")
      console.log({id_dette:this.$route.params.id, url:this.url})
      this.importerAmortissements({id_dette:this.$route.params.id, url:this.url})
    },
    goBack(){
      this.$router.push({name:"LeveeDeFonds"})
    },
    chargeEcheance(dette){
      this.paiements.amortissement = dette.id
      this.paiements.montant_paiement_dette = Math.round(Number(dette.montant_paiement_dette))
      this.montant_paiement_dette = dette.montant_paiement_dette.includes(".")?dette.montant_paiement_dette.split(".")[0]:dette.montant_paiement_dette
      this.addSpaceAmort("montant_paiement_dette")
      this.paiements.date_paiement_dette = dette.date_paiement_dette
      this.paiements.observation = dette.observation
    },
    loadPaiement(){
      this.postPaiement(this.paiements)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    differe(key,pkey){
      if(this[pkey]== 'oui'){
        this.$refs[key][0].disabled= true
      }else{
        this.$refs[key][0].disabled= false

      }
    }
  }
}
</script>
